

.content-popularity{
  padding: 12px 40px 0px 0px;
}
 .photo {
  /*width: 161px;*/
  width: 190px;
}

 .photo.left {
  float: left;
}

 .photo.right {
  float: right;
}

 .photo .wrapper {
  /* border-style: solid;
  border-width: 4px; */
  height: 172px;
  background-repeat: no-repeat;
  background-size: cover;
}

 .photo .wrapper.LuisAbinader {
  background-image: url('https://i.ibb.co/sH7RgCj/abinader.png') /*data/HillaryClinton.jpg*/;
}

 .photo .wrapper.PRM {
  background-image: url('https://pbs.twimg.com/media/Ecdj6BVWsAAVrk9?format=png&name=small') /*data/DonaldTrump.jpg*/;
}
 .photo.left .wrapper {
  border-color: #3689d6;
}

 .photo.right .wrapper {
  border-color: #e55253;
}
.photo .text2 {
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

 .photo .text {
  /* font-weight: bold; */
  text-align: center;
    line-height: 16px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 2px;
}

 .photo.left .text {
  /* color: #3689d6; */
}

 .photo.right .text {
  color: #e55253;
}



  .electoral {
  /*width: 100%;*/
  font-weight: bold;
 /* height: 41px;*/
  /*background-repeat: no-repeat;*/
  position: relative;
}
/*
  .electoral:first-child {
  background-image: url(./images/arrow-down.png);
  background-position: center bottom;
}

  .electoral:last-child {
  background-image: url(./images/arrow-up.png);
  background-position: center top;
}*/

  .electoral .description {
  font-size: 18px;
  font-weight: bold;
}

  .electoral .number {
  font-size: 24px;
  line-height: 22px;
}

  .electoral .left {
  /* float: left; */

}
.electoral .blue{
  /* color: #3689d6; */
}
.electoral .red{
  /* color: #e55253; */
}


  .electoral .right {
  float: right;
 
  text-align: right;
}

  .electoral .center {
  position: absolute;
  left: 50%;
  width: 20px;
  margin-left: -10px;
  color: lightgrey;
  font-size: 12px;
  top: 12px;
}

 .big-chart {
  display: block;
  height: 72px;
}