.dx-tile-content {
    height: 100%;
    padding: 0;
    position: relative;
}

.dx-tile-content .image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    padding: 10px;
    display: block;
}

.dx-tile {
    border: 1px solid #999;
}

.dx-tile.dx-state-hover {
    border-color: #EEE;
}

.dx-tile-content .price {
    font-size: 12px;
    position: absolute;
    bottom: 9px;
    left: 30px;
    
    color: white;
    opacity: 0.6;
    padding: 2px;
}
.dx-tile-image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    display: block;
}