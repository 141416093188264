header,
.content,
footer {
    /* margin: 0 40px; */
}
.gd-warning {
    color: #fff;
    border: none;
    background: #f4c414 linear-gradient(45deg, #f4c414, #f45414);
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

body{
    height: 100%;
}
html{
    height: 100%;
}
#root{
    height: 100%;
}
.content {
    padding: 60px 0;
    font-size: 0;
}

.content .left-side {
    width: 60%;
    display: inline-block;
    vertical-align: top;
}

.content .left-side p {
    text-align: justify;
}

.content .right-side {
    width: 40%;
    display: inline-block;
    vertical-align: top;
}


.headerr {
    font-size: 35px;
    line-height: 40px;
    border-bottom:2px solid #e6e4e4;
     background-image: url(https://i.ibb.co/SVFZHwC/Bandera-dominicana.png);
    background-repeat: no-repeat;
    background-position: right;
    
}

.header-gradient {
    padding: 12px 0;
    max-width: 700px;
    background: linear-gradient(to right, #fff 0%, #fff 55%, rgba(255, 255, 255, 0) 100%);
}

header .name {
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
}

footer {
    height: 35px;
    padding: 20px 0;
    background-color: #fff;
}

@media screen and (max-width: 1160px) {
    .content .left-side,
    .content .right-side {
        width: 100%;
    }

    .content .right-side {
        margin-top: 80px;
    }
}






.modal {
    /* background-image: linear-gradient(rgb(35, 79, 71) 0%, rgb(36, 121, 106) 100.2%) */
}

.modal-title {
    font-weight: 900
}

.modal-content {
    border-radius: 13px
}

.modal-body {
    color: #3b3b3b
}

.img-thumbnail {
    border-radius: 33px;
    width: 61px;
    height: 61px
}

.fab:before {
    position: relative;
    top: 13px
}

.smd {
    width: 200px;
    font-size: small;
    text-align: center
}

.modal-footer {
    display: block
}

.ur {
    border: none;
    background-color: #e6e2e2;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}

.cpy {
    border: none;
    background-color: #e6e2e2;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer
}

button.focus,
button:focus {
    outline: 0;
    box-shadow: none !important
}

.ur.focus,
.ur:focus {
    outline: 0;
    box-shadow: none !important
}

.message {
    font-size: 11px;
    color: #ee5535
}

.dx-theme-generic-typography a{
    color: white;
    width: 100px;
}


.loader {
    width: 100vw;
    height: 100vh;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
}

.loader-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}



.row.heading h2 {
    color: #fff;
    font-size: 52.52px;
    line-height: 95px;
    font-weight: 400;
    text-align: center;
    margin: 0 0 40px;
    padding-bottom: 20px;
    text-transform: uppercase;
}
ul{
  margin:0;
  padding:0;
  list-style:none;
}
.heading.heading-icon {
    display: block;
}
.padding-lg {
	display: block;
	padding-top: 60px;
	padding-bottom: 60px;
}
.practice-area.padding-lg {
    padding-bottom: 55px;
    padding-top: 55px;
}
.practice-area .inner{ 
     border:1px solid #999999; 
	 text-align:center; 
	 margin-bottom:28px; 
	 padding:40px 25px;
}
.our-webcoderskull .cnt-block:hover {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    border: 0;
}
.practice-area .inner h3{ 
    color:#3c3c3c; 
	font-size:24px; 
	font-weight:500;
	font-family: 'Poppins', sans-serif;
	padding: 10px 0;
}
.practice-area .inner p{ 
    font-size:14px; 
	line-height:22px; 
	font-weight:400;
}
.practice-area .inner img{
	display:inline-block;
}


.our-webcoderskull{
  background: url("http://www.webcoderskull.com/img/right-sider-banner.png") no-repeat center top / cover;
  
}
.our-webcoderskull .cnt-block{ 
   float:left; 
   width:100%; 
   background:#fff; 
   padding:30px 20px; 
   text-align:center; 
   border:2px solid #d5d5d5;
   margin: 0 0 28px;
}
.our-webcoderskull .cnt-block figure{
   width:148px; 
   height:148px; 
   border-radius:100%; 
   display:inline-block;
   margin-bottom: 15px;
}
.our-webcoderskull .cnt-block img{ 
   width:148px; 
   height:148px; 
   border-radius:100%; 
}
.our-webcoderskull .cnt-block h3{ 
   color:#2a2a2a; 
   font-size:20px; 
   font-weight:500; 
   padding:6px 0;
   text-transform:uppercase;
}
.our-webcoderskull .cnt-block h3 a{
  text-decoration:none;
	color:#2a2a2a;
}
.our-webcoderskull .cnt-block h3 a:hover{
	color:#337ab7;
}
.our-webcoderskull .cnt-block p{ 
   color:#2a2a2a; 
   font-size:13px; 
   line-height:20px; 
   font-weight:400;
}
.our-webcoderskull .cnt-block .follow-us{
	margin:20px 0 0;
}
.our-webcoderskull .cnt-block .follow-us li{ 
    display:inline-block; 
	width:auto; 
	margin:0 5px;
}
.our-webcoderskull .cnt-block .follow-us li .fa{ 
   font-size:24px; 
   color:#767676;
}
.our-webcoderskull .cnt-block .follow-us li .fa:hover{ 
   color:#025a8e;
}


.image-responsiv{
    max-height: calc(100vh - var(--header-height));
    height: auto;
    width: auto;
    max-width: 100%;
}
.image-contenedor-2{
    justify-content: center;
    position: relative;
    display: flex;
    align-items: center;
}

.image-contenedor{
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.Container1{
  display: flex;
  flex-direction: row;
  widows: 100%;
  height: 100%;
}

.Group2{  flex: 1 1 0%;
  align-self: stretch;
  flex-direction: column;
  display: flex;
  position: relative;}


.Group3{
  background-color: rgba(97,142,48,1);
  flex: 1 1 0%;
  align-self: stretch;
  display: flex;
  flex-direction: column;}

.Img1{
  top: 0px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  object-fit: contain;}

.React2{
  background-color: rgba(165,66,66,1);
  align-self: stretch;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;}

 
.button-google{
    margin-top: -4px;
}


.dx-popup-content{
    padding-left: 7px;
}
.nav-item .nav-link h5{
    color:#064d98;
} 


/*Mobile footer */

